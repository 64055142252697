import React from "react"
import PropTypes from "prop-types"
// Components
import Img from "gatsby-image"
import { Link, graphql } from "gatsby"
import SEOTAG from '../components/seotag'
import Layout from '../components/Layout'
import { Card, Row, Col } from 'react-bootstrap'
import { SlugLink } from '../components/utils'

const Tags = ({ location, pageContext, data }) => {
  //console.log(pageContext, data);
  const siteTitle = data.site.siteMetadata.title
  const domainName = data.site.siteMetadata.domainName
  const shortName = data.site.siteMetadata.shortName
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  let fullTag = tag;
  if (edges && edges.length > 0) {
    fullTag = edges[0].node.frontmatter.tags.filter(x => SlugLink(x) === tag);
  }
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
    } "${fullTag}"`
  if (location != null) {
    if (location.href != null && location.href.endsWith('/')) {
      window.location.href = window.location.href.substring(0, window.location.href.length - 1);
    }
  }

  return (
    <Layout location={location} title={`${siteTitle}`}>
      <SEOTAG
        title={`${fullTag} - ${shortName}`}
        description= {`${fullTag} - ${siteTitle}`}
      // keywords={[`${fullTag}`]}
      />
      <Card className="cardPost">
        <h1>{tagHeader}</h1>
      </Card>
          {edges.map(({ node }) => {
            let featuredImgFluid = node.frontmatter.cover.childImageSharp.fluid
            const title = node.frontmatter.title || node.fields.slug
            return (
              <div key={node.fields.slug}>
                <Card className="cardPost">
                  <Link style={{ boxShadow: 'none' }} to={node.fields.slug}>
                    <Row>
                      <Col xs={12} md={4}>
                        <Img fluid={featuredImgFluid} />
                      </Col>
                      <Col xs={12} md={8}>
                        {node.frontmatter.category !== null ? <Link style={{ color: 'rgba(0, 0, 0, .44)', }} to={`/category/${SlugLink(node.frontmatter.category)}`}>{node.frontmatter.category}</Link> : null}
                        <h2>{title}</h2>
                        <small style={{ color: '#000' }}>{node.frontmatter.date}</small>  <small style={{ color: 'rgba(0, 0, 0, .44)', }}>| {node.timeToRead} min read</small>
                        <p className="pdesc" dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                      </Col>
                    </Row>
                  </Link>
                </Card>
              </div>
            )
          })}
        {/*
              This links to a page that does not yet exist.
              You'll come back to it!
            */}
        <Link to="/tags" className="btn btn-all">All tags</Link>
    </Layout>
  )
}
Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export const pageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
        domainName
        shortName
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
      filter: { fields: { tags: { in: [$tag] } } }
    ) {
      totalCount
      
      edges {
        node {
          fields {
            slug
            tags
          }
          excerpt
          timeToRead
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            tags
            category
            cover {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
        }
      }
    }
  }
`
export default Tags